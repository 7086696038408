import React from "react"
import { Row, Col, Container } from "react-bootstrap"

import Layout from "components/Layout/en"
import Image from "components/Image/index"
import LayerAnimation from "components/LayerAnimation/LayerAnimation"
import Slider from "components/Slider/Simple"
import Star from "components/Icons/Star"
import Circle from "components/Icons/Circle"
import Readmore from "components/Readmore/index"
import TechnologyPartnerLogos from "components/OurPartnersSection/TechnologyPartnerLogos"

import imgHeader from "./technology.jpg"
import imgMachine from "./machineImg.jpg"
import imgMetalworkMachine from "./metalworkMachine.jpg"
import style from "./style.module.scss"

export default function(props) {
  const header = {
    x: 44,
    y: 75,
    text: "Deep Learning Technology",
    description:
      "Continuously learning automation technology for quality control.",
    image: imgHeader,
  }
  return (
    <Layout header={header} location={props.location}>
      <section className="grey-background">
        <Container>
          <Row className="py-5">
            <Col md="6">
              <Row className="py-6" style={{ marginTop: "-4vh" }}>
                <LayerAnimation />
              </Row>
            </Col>
            <Col md="6" className="py-5">
              <h4>Proprietary Deep Learning Technology</h4>
              <h3>
                Automated continuous learning process and improvement through
                the latest AI technology
              </h3>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="pt-5">
          <Col md="8" sm="6">
            <div>
              <h2>
                The Moonvision software specializes in quality control and
                assurance of assembly products and surfaces.
              </h2>
              <p>
                All data (image and video) are collected and processed via a
                visual data lake using <strong> Deep Learning </strong> to
                ensure a continuous learning process and continuous improvement
                in quality control. Since our technology is coordinated with the
                latest AI technology, the potential of our software includes an{" "}
                <strong> exponentially increasing learning capability </strong>{" "}
                and enables our model training to be the best possible Few-Shot
                object recognition on the market with minimal effort and data.
              </p>
              <p>
                In order to localize the product-specific features, a{" "}
                <strong> Labeling Tool </strong> is available which can be
                continuously adapted to each quality characteristic and which
                carries out a very precise and fully automated annotation of
                each deviation.
              </p>
            </div>
          </Col>
          <Col md="4" sm="6">
            <Image filename={imgMachine} alt="automated cutting cnc machine" />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="pt-5">
          <Col>
            <div className={style.tableDesign}>
              <table>
                <thead>
                  <tr>
                    <th>Data Management</th>
                    <th>Explanation</th>
                    <th>USP</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Team and Role Based Access Management</td>
                    <td>
                      Data protection and processing can be assigned to specific
                      units, persons and used across locations for fast
                      information exchange and best information security.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Infinite Versioning</td>
                    <td>
                      Our solution allows an error-tolerant generation of
                      training data, since any data input can be revoked.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Multiple Geometric Shapes</td>
                    <td>
                      The Moonvision Toolbox enables the identification of all
                      types of features with different geometric figures. This
                      ensures that actual and target states can be recorded
                      specifically and correctly for the application.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Video Snapshot Extraction</td>
                    <td>
                      Our company provides a tool, based on learning algorithms,
                      which allows savings in hardware components, since our
                      technology, can delimit processes in time and trigger
                      snapshots at the right time, like sensors.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Equivalent Inferences On-premise and Online</td>
                    <td>
                      Our technology enables online and offline deploys with
                      100% equal results. You can perform online activities and
                      virtual tests flexible and update the toolbox offline with
                      the new results. With this we guarantee process
                      reliability.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Cloud Scale GPU Utilization</td>
                    <td>
                      All data is collected and processed by a central
                      cloud-based visual Data Lake. We provide analysis results
                      quickly because GPUs are available permanently and
                      scalingly.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Machine Vision and IP Camera</td>
                    <td>
                      We support GeniCam standards (e.g. Basler, IDS, ...) and
                      common video streaming standards based on IP (e.g. H264,
                      RTSP, HTTP).
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Transfer Learning</td>
                    <td>
                      Our pre-defined software reduces the need of training data
                      by a factor of 3-10, as we can apply industry-specific
                      know-how for the specialization of models. This enables us
                      to identify new and rare phenomena.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                  <tr>
                    <td>Anomaly Segmentation</td>
                    <td>
                      We can localize unknown and conspicuous features, such as
                      conspicuous fiber structure, surface texture, component
                      composition.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Traceback Predictions to Single Training Samples</td>
                    <td>
                      Our technology has automatic procedures for detecting
                      incorrect entries in the training data.
                    </td>
                    <td>
                      <Circle />
                    </td>
                  </tr>
                  <tr>
                    <td>Model Introspection</td>
                    <td>
                      The Moonvision software visualizes the functioning of
                      image analysis steps, whereby model improvements are
                      enabled systematically and not randomly.
                    </td>
                    <td>
                      <Star />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
      <Slider />
      <Container>
        <Row className="py-5">
          <Col md="4">
            <Image filename={imgMetalworkMachine} alt="metal-work-machine" />
          </Col>
          <Col md="8">
            <h3>
              It is important for our company that every company can use our
              software with simple resources.
            </h3>
            <p>
              This, as one of our goals, allows every camera system to be linked
              to our software and allows us to remain hardware independent. By
              choosing image processing systems freely, we can process features
              down to the <strong> microscopic level </strong> and identify
              every critical detail.
            </p>
            <p>
              Since our software is self-sufficiently designed, it is the
              perfect solution for every company and every industry. The{" "}
              <strong> versatile application </strong> possibilities of our
              product allow our products to be integrated into any manufacturing
              process. Thus, our solution can be coupled simply and by small
              measures into a production process both at arrival and departure
              of goods as well as after each processing step. Due to the
              different industries, we also consider it necessary to analyze
              moving products in the production flow in addition to the standing
              product controls in order to avoid any disruptions.
            </p>
            <p>
              <strong>
                Due to the great diversity of our technology, it is sustainably
                designed and can be optimised for operation depending on the
                requirements.
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
      <TechnologyPartnerLogos />
      <Container>
        <Row className="py-5">
          <Col md="12">
            <h2>Learn more about our technology on medium</h2>
          </Col>
          <Readmore />
        </Row>
      </Container>
    </Layout>
  )
}
